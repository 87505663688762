var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      Object.prototype.toString.call(_vm.value) === "[object Array]" &&
      _vm.getDocFileWebConf
        ? [
            _vm._l(_vm.value, function (d) {
              return _c(
                "span",
                { key: d.name, staticClass: "attachment-wrapper" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "attachment",
                      attrs: {
                        href:
                          _vm.getDocFileWebConfUrl + "?url=" + (d.url || ""),
                        target: "_blank",
                      },
                    },
                    [
                      _c("i", { staticClass: "iconfont iconmd-document" }),
                      _vm._v(
                        " " +
                          _vm._s(d.name) +
                          " " +
                          _vm._s(d.Size ? "(" + d.Size + ")" : "")
                      ),
                    ]
                  ),
                ]
              )
            }),
            _vm._v(" \n  "),
          ]
        : _vm._e(),
      _vm._v(" "),
      Object.prototype.toString.call(_vm.value) === "[object Array]" &&
      !_vm.getDocFileWebConf
        ? [
            _vm._l(_vm.value, function (d) {
              return _c(
                "span",
                { key: d.name, staticClass: "attachment-wrapper" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "attachment",
                      attrs: { href: "" + (d.url || "") },
                    },
                    [
                      _c("i", { staticClass: "iconfont iconmd-document" }),
                      _vm._v(
                        " " +
                          _vm._s(d.name) +
                          " " +
                          _vm._s(d.Size ? "(" + d.Size + ")" : "")
                      ),
                    ]
                  ),
                ]
              )
            }),
            _vm._v(" \n  "),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }