var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DropMultiSelectFilter",
        _vm._g(
          _vm._b(
            { ref: "DropDownSelectFilter" },
            "DropMultiSelectFilter",
            _vm.propsDataCom,
            false
          ),
          _vm.eventFunction
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }