var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bcl-ImageUpload" },
    [
      _c("PreviewPicture", {
        attrs: {
          images: _vm.defaultProps.valuedata,
          show: _vm.show,
          defaultIndex: _vm.defaultIndex,
        },
        on: { "on-cancel": _vm.cancel },
      }),
      _vm._v(" "),
      _vm.percent > 0
        ? _c(
            "i-circle",
            {
              staticClass: "percent",
              style:
                "height:" +
                (_vm.defaultProps.height - 20) +
                "px;width:" +
                (_vm.defaultProps.width - 20) +
                "px;",
              attrs: { percent: _vm.percent },
            },
            [
              _c(
                "span",
                {
                  staticClass: "demo-Circle-inner",
                  staticStyle: { "font-size": "24px" },
                },
                [_vm._v(_vm._s(_vm.percent) + "%")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ImageUpload", {
        attrs: { dataitem: _vm.newProps },
        on: {
          deleteImg: function (item, index) {
            return _vm.deleteImg(item, index)
          },
          uploadFileChangeSuccess: _vm.uploadFileChangeSuccess,
          uploadFileChangeOnerror: _vm.uploadFileChangeOnerror,
          uploadFileChangeProgress: _vm.uploadFileChangeProgress,
          dblclick: _vm.uploadFileDblclick,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }