import { render, staticRenderFns } from "./TextComponent.vue?vue&type=template&id=60e78b94&scoped=true&"
import script from "./TextComponent.vue?vue&type=script&lang=js&"
export * from "./TextComponent.vue?vue&type=script&lang=js&"
import style0 from "./TextComponent.vue?vue&type=style&index=0&id=60e78b94&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e78b94",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chenzihao/code/workspace/arkui-bus/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60e78b94')) {
      api.createRecord('60e78b94', component.options)
    } else {
      api.reload('60e78b94', component.options)
    }
    module.hot.accept("./TextComponent.vue?vue&type=template&id=60e78b94&scoped=true&", function () {
      api.rerender('60e78b94', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common-table-by-ag-grid/renderComponents/TextComponent.vue"
export default component.exports