var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        _vm._b(
          {
            ref: "preview",
            attrs: {
              value: _vm.show,
              styles: { position: "absolute", top: "12%" },
            },
            on: { "on-cancel": _vm.cancel },
          },
          "Modal",
          _vm.ModalProps,
          false
        ),
        [
          _c("viewer", {
            ref: "viewer",
            staticClass: "viewer",
            attrs: { options: _vm.options, images: _vm.images },
            on: { inited: _vm.inited },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "figure",
                      { staticClass: "images" },
                      _vm._l(scope.images, function (ref, index) {
                        var URL = ref.URL
                        return _c(
                          "div",
                          { key: index, staticClass: "image-wrapper" },
                          [
                            _c("img", {
                              staticClass: "image",
                              attrs: { src: URL, "data-source": URL },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }