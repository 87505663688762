var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.type === "dropdown" ? "dropdown" : "div",
    {
      tag: "component",
      staticClass: "inline-block",
      on: { "on-click": _vm.handleDropClick },
    },
    [
      _vm.enablePop
        ? _c(
            "Poptip",
            {
              attrs: {
                trigger: _vm.popTrigger,
                transfer: "",
                wordWrap: _vm.popWordWrap,
                width: _vm.popWidth,
                content: _vm.popContent,
                placement: _vm.placement,
              },
            },
            [
              _c(
                "Button",
                {
                  class: _vm.prefixClass,
                  attrs: { type: _vm.type },
                  on: { click: _vm.handleClick },
                },
                [
                  _vm._t("default"),
                  _vm._v(" "),
                  _vm.type === "dropdown"
                    ? _c("Icon", { attrs: { type: "ios-arrow-down" } })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : [
            _c(
              "Button",
              {
                class: _vm.prefixClass,
                attrs: { type: _vm.type },
                on: { click: _vm.handleClick },
              },
              [
                _vm._t("default"),
                _vm._v(" "),
                _vm.type === "dropdown"
                  ? _c("Icon", { attrs: { type: "ios-arrow-down" } })
                  : _vm._e(),
              ],
              2
            ),
          ],
      _vm._v(" "),
      _c(
        "template",
        { slot: "list" },
        [
          _vm.$slots.list
            ? _vm._t("list")
            : _c(
                "DropdownMenu",
                _vm._l(_vm.data, function (item) {
                  return _c(
                    "DropdownItem",
                    { key: item, attrs: { name: item } },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                1
              ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }