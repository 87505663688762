var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ag-image-component " },
    [
      _vm.url.length > 0
        ? _c("img", {
            staticClass: "ag-syman-hover",
            attrs: { src: _vm.url[0].URL, alt: "" },
            on: {
              mouseenter: _vm.mouseenter,
              mouseleave: _vm.mouseleave,
              dblclick: _vm.dblclick,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("pictureViewer", {
        attrs: {
          images: _vm.url,
          show: _vm.showViewer,
          mountedDom: _vm.mountedDom,
        },
        on: { "on-cancel": _vm.closePreview },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }