var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ag-grid-table",
        _vm._g(
          _vm._b(
            {
              ref: "agGridTable",
              class: [_vm.border ? "ag-border" : ""],
              attrs: {
                options: _vm.filteredOption,
                columns: _vm.columns,
                data: _vm.data,
                renderParams: _vm.renderFn,
                mode: _vm.mode,
              },
              on: {
                "on-selection-change": _vm.tableSelectedChange,
                "on-row-dblclick": _vm.tableRowDbclick,
                "grid-ready": _vm.tableGridReady,
              },
            },
            "ag-grid-table",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }